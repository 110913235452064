import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { PageContentBuilder } from "../components/partials/page-content-builder";

const Page = ({ data, location}) => {
    const { contentNode = {} } = data;
    let  { values = "", meta = [], identifier = "" } = contentNode;
    values = JSON.parse(values);

    return <PageContentBuilder 
        headerActive={values.title} 
        location={location} 
        title={values.title}
        api={true} 
        PageContent={values} 
        subsection={identifier}
        meta={meta}
    />
}

const indexQuery = graphql`
  query {
    contentNode(identifier: {eq: "partial_careers"}) {
        identifier
        values
        meta {
            content
            name
        }
        
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <Page location={props.location} data={data} {...props} />
    )}
  />
)